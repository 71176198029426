<template>
    <div class="question-details">
        <div class="question-main" v-html="questionList.Content"></div>
        <div class="fixed-area" v-html="questionList.FixedContentStr"></div>
        <div class="operation">
            <div class="operation-title">以上方案是否解决了您的问题？</div>
            <div class="operation-btn">
                <div :class="questionList.IsReplyed?questionList.ReplyedResult?'resolved':'default-resolved':'default-resolved'" 
                    @click="chooseBtn(questionList.IsReplyed,1)">已解决</div>
                <div :class="questionList.IsReplyed?!questionList.ReplyedResult?'unresolved':'default-unresolved':'default-unresolved'" 
                    @click="chooseBtn(questionList.IsReplyed,0)">未解决</div>
            </div>
            <div class="operation-other" @click="call" id="call">
                没有解决您的问题？<span>联系客服</span><img src="../../assets/images/app-news/call.png" />
            </div>
        </div>
        <div class="question-list">
            <div class="list-head">
                <div class="list-icon"></div>
                <div class="list-title">以下内容可能对你有帮助：</div>
            </div>
            <ul class="list-item">
                <li class="list-con" v-for="questionItem in questionList.relatedQuestionList" :key="questionItem.HelpCenterQuestionId">
                  <span @click="pushPage(questionItem.HelpCenterQuestionId,questionItem.Title)"> > {{questionItem.Title}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            questionList:[],
            query:{
                QuestionId:this.$route.query.QuestionId,
                UserId:this.$route.query.UserId,
                Ip:this.$route.query.Ip,
            },
            //android终端
            isAndroid : window.navigator.userAgent.indexOf('Android') > -1 || window.navigator.userAgent.indexOf('Adr') > -1,
            //ios终端
            isiOS : !!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        }
    },
    created(){
        this.getQuestionData();
    },
    methods:{
        //获取问题的详情数据
        getQuestionData(){
            this.$get(this.$api.GetQuestionContainRelatedQuestionsByH5,{
                ...this.query
            })
            .then((res)=>{
                console.log(res)
                this.questionList = res.Data;
            }).catch(err=>{console.log(err);});
        },
        pushPage(id,title){
            console.log(id,title)
            let questionId = id;
            let questionTitle = title;
            if(this.isAndroid){
                window.andorid.toPage(questionId+'-'+questionTitle)
            }else if(this.isiOS){
                window.webkit.messageHandlers.toPage.postMessage(questionId+'-'+questionTitle)
            }
        },
        call(){
            console.log("联系客服")
            let phone = "4001897900";
            if(this.isAndroid){
                window.andorid.callPhone(phone)
            }else if(this.isiOS){
               window.webkit.messageHandlers.callPhone.postMessage(phone)
            }
        },
        chooseBtn(isOperation,type){
            console.log(isOperation,type)
            let operation=isOperation?operation="true":operation="false";
            if(this.isAndroid){
                window.andorid.chooseBtn(operation+'-'+type)
            }else if(this.isiOS){
                window.webkit.messageHandlers.chooseBtn.postMessage(operation+'-'+type)
            }
        }
    }
}
</script>
<style lang="stylus">
.question-details{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0 16px;
    background: #fff;
    .question-main{
        padding-top:15px;
        padding-bottom: 40px
        border-bottom: 1px solid #eee;
        span{
            font-size: 19px;
        }
        p{
            font-size: 14px;
            font-weight: normal;
            line-height: 21px;
            color: #0F1826;
            text-indent:2em;
            margin: 5px 16px;
        }
        img{
            text-indent: 0;
            width:86%;
            height:auto;
            margin:30px auto;
        }
    }
    .fixed-area{
       margin-top: 30px;
        padding-bottom: 35px
        border-bottom: 1px solid #eee;
    }
    .operation{
        margin:30px 0;
        text-align:center;
        .operation-title{
            height: 22px;
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
            color: #374567;
        }
        .operation-btn{
            display: flex;
            justify-content: space-evenly;
            margin-top: 24px;
            div{
                width: 110px;
                height: 35px;
                line-height: 35px;
                border: 1px solid #7A8BA6;
                border-radius: 3px;
                font-size: 14px;
                font-weight: 400;
                color: #7A8BA6;
                padding-left: 30px;
            }
            .resolved{
                border:0;
                color: #387EE8;
                background: #EEF4FF url(../../assets/images/app-news/yjj-2.png) no-repeat 20px;
            }
            .unresolved{
                border:0;
                color: #387EE8;
                background: #EEF4FF url(../../assets/images/app-news/wjj-2.png) no-repeat 20px;
            }
            .default-resolved{
                background:url(../../assets/images/app-news/yjj-1.png) no-repeat 20px;
                
            }
            .default-unresolved{
                background:url(../../assets/images/app-news/wjj-1.png) no-repeat 20px;
            }
        }
        .operation-other{
            margin-top: 30px;
            height: 20px;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            color: #0F1826;
            span{
                color:#3385FF;
            }
            img{
                position: absolute;
                margin-left: 5px;
            }
        }
    }
    /* 问题详情对应的其他问题列表 */
    .question-list{
        padding-bottom: 30px;
        .list-head{
            height:22px;
            .list-icon{
                float: left
                width: 3px;
                height: 18px;
                background: #0FC8EE;
                margin-top: 2px;
            }
            .list-title{
                height: 22px;
                font-size: 16px;
                font-weight: bold;
                line-height: 22px;
                color: #333333;
                margin-left: 15px;
            }
        }
        .list-item{
            margin-top: 15px;
            padding:15px 20px;
            background: #FBFBFB;
            .list-con{
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                color: #3C3C3C;
                letter-spacing: 1px;
                margin-bottom: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .list-con:last-child{
                margin-bottom: 0;
            }
        }
    }
}
</style>